import React, { useState } from 'react';
import './index.css';
import firstImage from '../../images/full-logo.png';
import './responsiveHire.css';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import { labelNames, developers, labelNamesSecond, developersSecond } from '../../../assets';

const HireADevPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [firstAnswer, setFirstAnswer] = useState('');
  const [secondAnswer, setSecondAnswer] = useState('');

  const developerItems = developers.map((developers, i) => (
    <input
      key={developers.id}
      id={developers.id}
      type={developers.type}
      name={developers.name}
      value={developers.value}
      onChange={() => setFirstAnswer(labelNames[i])}
    />
  ));

  const developerItemsSecond = developersSecond.map((developersSecond, i) => (
    <input
      key={developersSecond.id}
      id={developersSecond.id}
      type={developersSecond.type}
      name={developersSecond.name}
      value={developersSecond.value}
      onChange={() => setSecondAnswer(labelNamesSecond[i])}
    />
  ));

  const [currentIndex, setCurrentIndex] = useState(0);
  const changeContent = async () => {
    if (!firstAnswer && currentIndex === 0) return;
    if (!secondAnswer && currentIndex === 1) return;
    if (currentIndex > 1) return;

    setTimeout(() => {
      setCurrentIndex(currentIndex + 1);
    }, 0);
  };

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>Hire a dev</title>
        <meta
          name="description"
          content={
            'Coetic X is a software development company interested in scaling up, growing the network, connecting with teams around the world and assisting them with high quality code. A team of handpicked developers that are highly efficient, have a progressive mentality and an immense passion for programming.'
          }
        />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content="Hire a dev" />
        <meta property="og:url" content="/" />
        <meta name="google-site-verification" content="TAZyvCwfNRod7T0FvgzEaEznjHHjLWds9xo3qjgcoOQ" />
      </Helmet>

      <h1 style={{ display: 'none' }}>Hire a dev</h1>
      <h2 style={{ display: 'none' }}>
        Coetic X is a software development company interested in scaling up, growing the network, connecting with teams around the world and assisting them with
        high quality code.
      </h2>
      <p style={{ display: 'none' }}>Hire a dev</p>

      <form method="post" netlify={true} action="/.netlify/functions/contact">
        <div className="main-hire-page">
          <div className="hire-container">
            <div className="first-page-questions">
              <Link className="coetic-x-logo" to="/">
                <img className="coetic-x" src={firstImage} alt="coetic-x logo" />
              </Link>

              <div className="questions">
                <p className="first-question">
                  {Boolean(currentIndex === 0) && 'What are you looking for in your project?'}
                  {Boolean(currentIndex === 1) && 'How long do you need a developer?'}
                  {Boolean(currentIndex === 2) && 'Please write down your email address so that we can contact you'}
                </p>

                {Boolean(currentIndex === 0) && (
                  <div>
                    {developerItems.map((item, index) => {
                      return (
                        <label htmlFor={item.id} className="form-elements">
                          {item}
                          <span>{labelNames[index]}</span>
                        </label>
                      );
                    })}
                  </div>
                )}

                {Boolean(currentIndex === 1) && (
                  <div>
                    {developerItemsSecond.map((item, index) => {
                      return (
                        <label htmlFor={item.id} className="form-elements">
                          {item}
                          <span>{labelNamesSecond[index]}</span>
                        </label>
                      );
                    })}
                  </div>
                )}

                {Boolean(currentIndex === 2) && (
                  <div>
                    <input type="text" name={'firstAnswer'} value={firstAnswer} hidden />
                    <input type="text" name={'secondAnswer'} value={secondAnswer} hidden />
                    <div className="d-flex position-relative mt-5 mb-2">
                      <p className="material-icons email">person</p>
                      <input
                        className="hire-input"
                        type="text"
                        name="name"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder="First and Last Name"
                        required
                      />
                    </div>
                    <div className="d-flex position-relative mb-5">
                      <p className="material-icons email">email</p>
                      <input
                        className="hire-input"
                        type="email"
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        placeholder="E-Mail"
                        required
                      />
                    </div>
                  </div>
                )}

                {Boolean(currentIndex === 3) && (
                  <div className="questionsSecond">
                    <p className="material-icons email ">email</p>
                    <input type="email" className="emailinput" placeholder="Write your e-mail" />
                  </div>
                )}

                {Boolean(currentIndex === 4) && (
                  <div className="done-icona">
                    <span className="material-icons check">check_circle</span>
                    <div className="last-slide-content">
                      <p className="last-content-massage">Thank you so much for your message! </p>
                      <p className="last-content-paragraph">Our team will respond to you within 24 hours.</p>
                    </div>
                  </div>
                )}
              </div>

              <div className="hire-page-line"></div>

              <div className="hire-button">
                <button class="button-next" onClick={changeContent} type={currentIndex === 2 ? 'submit' : 'button'}>
                  <span>
                    {Boolean(currentIndex === 0) && 'Get Started'}
                    {Boolean(currentIndex === 1) && 'Next'}
                    {Boolean(currentIndex === 2) && 'Submit'}
                    {Boolean(currentIndex === 4) && 'Go to main page'}
                  </span>
                  <span>
                    <p noindex className="material-icons arrow ">
                      arrow_circle_right
                    </p>
                  </span>
                </button>
              </div>
            </div>

            <div className="first-page-logo">
              <svg id="Group_73" data-name="Group 73" xmlns="http://www.w3.org/2000/svg" width="50.206" height="65.219" viewBox="0 0 50.206 65.219">
                <defs>
                  <clipPath id="clip-path">
                    <path
                      id="Path_1"
                      data-name="Path 1"
                      d="M95.148,550.594v7.247l6.276,3.623,6.276,3.623,6.276,3.623v-7.246L107.7,557.84l-6.276-3.624-6.276-3.623,6.276-3.623v-7.247l-6.276,3.623-6.276,3.624L82.6,550.594v-7.247l-6.276-3.623L70.045,536.1l-6.276-3.623v7.246l6.276,3.623,6.276,3.624,6.276,3.623-6.276,3.623v7.247L82.6,557.84l6.276-3.624Zm6.276,18.117-6.276,3.623-6.276,3.623L82.6,572.334l-6.276-3.624-6.276-3.623V550.594l-6.276-3.623v21.74l6.276,3.624,6.276,3.623L82.6,579.58l6.276,3.623,6.276-3.623,6.276-3.623,6.276-3.623ZM107.7,536.1v7.246l6.276-3.623v-7.246l-6.276-3.624-6.276-3.623-6.276-3.623-6.276-3.623L82.6,521.607l-6.276,3.623-6.276,3.623,6.276,3.624,6.276-3.624,6.276-3.623,6.276,3.623,6.276,3.624Z"
                      transform="translate(-63.769 -517.984)"
                      fill="#fff"
                      clip-rule="evenodd"
                    />
                  </clipPath>
                </defs>
                <g id="Group_1" data-name="Group 1" clip-path="url(#clip-path)">
                  <rect id="Rectangle_1" data-name="Rectangle 1" width="50.206" height="65.22" transform="translate(0 0)" fill="#fff" />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default HireADevPage;

// import Calendar from 'react-calendar';
//   const [date, setDate] = useState(new Date());
//   const onChange = date => {
//     setDate(date);
//   };
//  {Boolean(currentIndex === 2) && (
//     <div className="calendar-area">
//       <Calendar onChange={onChange} value={date}></Calendar>
//       <div className="days-week-month">
//         <p className="tue-dec">Tuesday, December 7</p>
//         <div className="year-buttons">
//           <button className="time-button" href="#">
//             10:00
//           </button>
//           <button className="time-button" href="#">
//             13:00
//           </button>
//         </div>
//       </div>
//     </div>
//   )}
